.navigation-container{
    /* height: 100px; */
    padding: 20px 0px;
    background-color: var(--bg-grey);
    display: block ;
    align-items: center;
    justify-content: center;

}

.nav-background{
    background-color: var(--bg-grey);
    position: relative;
}

.nav-logo figure{
    margin: 0;
    padding: 0;
    height: 40px;
    width: 40px;
}

.nav-logo figure img{

    height: 100%;
    width: 100%;
    object-fit: contain;
}

.navigation-left{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navigation-links{
    width: 40%;
    padding: 9px 0px;
    border-right: 2px solid ;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.nav-links{
    text-decoration: none;
    font-weight: 700;
    color: black;
}

.navigation-cart-and-sign{
    width: 55%;
    /* border: 2px solid; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-cart-btn{
    width: 20%;
/* border: 2px solid ; */
font-weight: 700;

}

.nav-cart-btn button{
    border: none ;
    background-color: transparent;
}

.nav-search{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-light);
    padding: 10px ;
    border-radius: 20px;
}

.nav-search figure{
    margin: 0;
    height: 20px;
    width: 20px;
}


.nav-search figure img{
    height: 100%;
    width: 100%;
    object-fit: contain ;
}

.nav-search  input{
    border: none;
    outline: none ;
    width: 90%;
    background-color: transparent;
}

.nav-log-and-sign{
    width: 35%;
    border-left: 2px solid ;
    display: flex;
    justify-content: space-around;
}

.nav-log-and-sign button{
   border: none;
   background-color: transparent;
   font-weight: 600;
}

.nav-log-and-sign button:nth-child(2){
    background-color: black;
    color: var(--bg-light);
    padding: 9px 25px;
    border-radius: 10px;
    font-weight: 700;
}


.nav-link {
    color: black!important;
    font-weight: 600;
}

.btn-outline-light {
    --bs-btn-color:black;
    --bs-btn-border-color: black;
}

.bg-dark {
    --bs-bg-opacity: none !important;

}

.navbar-dark .navbar-toggler {
    background: black;
    color: black;
    border-color: rgba(255,255,255,.1);
}

.signupbtn{
    background-color: black;
    color: var(--bg-light);
    padding: 9px 25px;
    border-radius: 10px;
    font-weight: 700;
}

.login{
        border: none;
        background-color: transparent;
        font-weight: 600;
}

.wishlist-length{
    position: absolute;
     top:8px ;
     left:60%;
    color:#ec4f8c
}

@media(max-width: 400px){
    .cart-and-search{
        display:block;
    }

    .wishlist-length{
        position: absolute;
         top:210px ;
         left:25%;
        color:#ec4f8c
    }
}